import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useCreditCardFlag } from '../../hooks/useCreditCardFlag';
import {
  numberMask,
  brlMask,
  expireAtMask,
  onDelete,
} from '../../helpers/masks';

export const Token = ({
  cctoken,
  fillToken,
  t,
  installments,
  calc,
  setAmount,
}) => {
  const { flags } = useCreditCardFlag();
  const [flag, setFlag] = useState('DEFAULT');

  const countriesNew = t('cctoken.fields.country.options', {
    returnObjects: true,
  });

  const countries = countriesNew.sort((a, b) => {
    const codeA = a.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const codeB = b.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return codeA < codeB ? -1 : codeA > codeB ? 1 : 0;
  });

  const [disableInstallments, setDisableInstallments] = useState(true);
  const [amountByInstallments, setAmountByInstallments] = useState([]);

  useEffect(() => {
    const initialize = () => {
      const prices = [];
      for (let i = 1; i <= installments; i++) {
        const newPrice =
          Math.round((calc.credit_card[i.toString()] / i) * 100) / 100;
        prices.push({
          installments: i,
          price: newPrice,
          fullPrice: calc.credit_card[i.toString()],
        });
      }
      console.log('prices', prices);
      setAmountByInstallments(prices);
    };
    initialize();
  }, [calc, installments]);
  return (
    <>
      <h3 className='text-2xl font-extrabold lg:leading-[50px] mb-3'>
        {t('cctoken.fields.title')}
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`cctoken-number`}
          >
            {t('cctoken.fields.number')}
          </label>
          <div className='d-flex flex-row gap-3 justify-content-between align-items-center'>
            <input
              id={`cctoken-number`}
              value={cctoken.number}
              className='form-control'
              onChange={(e) => {
                const { value } = e.target;
                let newValue = numberMask(value);
                for (let key in flags) {
                  if (flags[key].pattern.test(newValue)) {
                    setFlag(key.toString());
                    newValue = flags[key].mask(newValue);
                    fillToken('number', newValue);
                    break;
                  }
                }
              }}
              onKeyDown={(e) => {
                const newValue = onDelete(e, cctoken.number);
                fillToken('number', newValue);
              }}
              autoComplete='off'
            />
            <img
              className=''
              src={flags[flag].src}
              alt='Bandeira do cartão'
            />
          </div>
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`cctoken-name`}
          >
            {t('cctoken.fields.holdername')}
          </label>
          <input
            id={`cctoken-name`}
            value={cctoken.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;

              fillToken('name', value.toUpperCase());
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          className='mb-3'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-country`}
          >
            {t('cctoken.fields.country.name')}*
          </label>
          <select
            id={`cctoken-country`}
            className='form-control select-style'
            autoComplete='off'
            value={cctoken.country}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              setDisableInstallments(!(newValue === 'BR'));
              if (!(newValue === 'BR')) {
                fillToken('installments', 1);
              }

              fillToken('country', newValue);
            }}
          >
            <option value={''}>{t('cctoken.fields.country.select')}</option>
            {countries.map((country, index) => (
              <option
                key={index}
                value={country.code}
              >
                {country.name}
              </option>
            ))}
          </select>
        </Col>
        <Col
          md={3}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`cctoken-expireAt`}
          >
            {t('cctoken.fields.expireDate')}
          </label>
          <input
            id={`cctoken-expireAt`}
            maxLength={5}
            value={cctoken.expireAt}
            placeholder='mm/aa'
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              let newValue = expireAtMask(value);
              fillToken('expireAt', newValue);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, cctoken.expireAt);
              fillToken('expireAt', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`cctoken-code`}
          >
            {t('cctoken.fields.cvc')}
          </label>
          <input
            id={`cctoken-code`}
            type='password'
            maxLength={4}
            value={cctoken.code}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = numberMask(value);
              fillToken('code', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='mb-3'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`token-amount`}
          >
            {t('cctoken.fields.installments')}
          </label>
          <select
            id={`token-installments`}
            className='form-control select-style'
            disabled={disableInstallments}
            value={cctoken.installments}
            onChange={(e) => {
              const { value, key } = e.target;
              console.log('value', value);
              console.log('key', key);
              const newValue = amountByInstallments.find(
                (produto) => produto.installments === parseInt(value),
              ).fullPrice;
              //amountByInstallments[parseInt(key)].fullPrice;
              setAmount(newValue);
              fillToken('installments', parseInt(value));
            }}
          >
            {amountByInstallments.map((price, index) => (
              <option
                key={price.installments}
                value={price.installments}
              >
                {`${price.installments}x ${brlMask(price.price)}`}
              </option>
            ))}
          </select>
        </Col>
      </Row>
    </>
  );
};
