import { Row, Col } from 'reactstrap';
import {
  onDelete,
  alphabetMask,
  emailMask,
  mobileMask,
  documentMask,
} from '../../helpers/masks';

export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  length,
  t,
  email,
  isValidEmail,
  isValidDocument,
}) => {
  const occupations = t('attendee.fields.occupations', {
    returnObjects: true,
  });
  return (
    <>
      <h3 className='text-2xl font-extrabold lg:leading-[50px] mb-3'>
        {t('attendee.title.start')} {length === 1 ? '' : `${index + 1}º`}{' '}
        {t('attendee.title.end')}
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-name`}
          >
            {t('attendee.fields.name')}
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-email`}
          >
            {t('attendee.fields.email')}
          </label>
          <input
            disabled={email !== ''}
            type='email'
            id={`attendee-${index}-email`}
            className='form-control'
            name='email'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
            onBlur={async (e) => {
              e.preventDefault();
              await isValidEmail(attendee.email, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-document`}
          >
            {t('attendee.fields.document')}
          </label>
          <input
            id={`attendee-${index}-document`}
            className='form-control'
            autoComplete='off'
            value={attendee.document}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = documentMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
            onBlur={async (e) => {
              e.preventDefault();
              await isValidDocument(attendee.document, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-mobile`}
          >
            {t('attendee.fields.mobile')}
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            maxLength={15}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-address`}
          >
            {t('attendee.fields.address')}
          </label>
          <input
            id={`attendee-${index}-address`}
            className='form-control'
            autoComplete='off'
            value={attendee.address}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('address', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-birthdate`}
          >
            {t('attendee.fields.birthdate')}
          </label>
          <input
            type='date'
            min='1923-01-01'
            max='2024-12-31'
            id={`attendee-${index}-birthdate`}
            className='form-control'
            autoComplete='off'
            value={attendee.birthdate}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('birthdate', value, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-gender`}
          >
            {t('attendee.fields.gender')}
          </label>
          <input
            id={`attendee-${index}-gender`}
            className='form-control'
            autoComplete='off'
            value={attendee.gender}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('gender', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-occupation`}
          >
            {t('attendee.fields.occupation')}
          </label>
          <select
            id={`attendee-${index}-occupation`}
            className='form-control'
            autoComplete='off'
            value={attendee.occupation}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('occupation', value, index);
            }}
          >
            {occupations.map((occupation, index) => (
              <option
                key={index}
                value={occupation.code}
              >
                {occupation.value}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
          hidden={
            !(attendee.occupation === t('attendee.occupations.psicologo'))
          }
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-crp`}
          >
            {t('attendee.fields.crp')}
          </label>
          <input
            id={`attendee-${index}-crp`}
            className='form-control'
            autoComplete='off'
            value={attendee.crp}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('crp', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={
            !(
              attendee.occupation === t('attendee.occupations.psiquiatra') ||
              attendee.occupation === t('attendee.occupations.medicina')
            )
          }
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-crm`}
          >
            {t('attendee.fields.crm')}
          </label>
          <input
            id={`attendee-${index}-crm`}
            className='form-control'
            autoComplete='off'
            maxLength={13}
            value={attendee.crm}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('crm', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={
            !(
              attendee.occupation ===
                t('attendee.occupations.estudante_psicologia') ||
              attendee.occupation ===
                t('attendee.occupations.estudante_residente_psquiatria') ||
              attendee.occupation ===
                t('attendee.occupations.estudante_medicina') ||
              attendee.occupation ===
                t('attendee.occupations.residente_medicina') ||
              attendee.occupation ===
                t('attendee.occupations.residente_psquiatria')
            )
          }
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-institution`}
          >
            {t('attendee.fields.institution')}
          </label>
          <input
            id={`attendee-${index}-institution`}
            className='form-control'
            autoComplete='off'
            value={attendee.institution}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('institution', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={
            !(attendee.occupation === t('attendee.occupations.outras_areas'))
          }
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-which`}
          >
            {t('attendee.fields.which')}
          </label>
          <input
            id={`attendee-${index}-which`}
            className='form-control'
            autoComplete='off'
            value={attendee.which}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('which', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={
            !(attendee.occupation === t('attendee.occupations.outras_areas'))
          }
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-registrationid`}
          >
            {t('attendee.fields.registrationid')}
          </label>
          <input
            id={`attendee-${index}-registrationid`}
            className='form-control'
            autoComplete='off'
            value={attendee.registrationid}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('registrationid', value, index);
            }}
          />
        </Col>
      </Row>
      <Row className='align-items-start'>
        <Col>
          <div className='form-check mt-3'>
            <input
              id={`attendee-${index}-term_1`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.term_1}
              onChange={(e) => {
                fillAttendee('term_1', !attendee.term_1, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p>
                <a
                  target='_blank'
                  href={t('attendee.fields.term_1_link')}
                  rel='noreferrer'
                >
                  {t('attendee.fields.term_1')}
                </a>
                .
              </p>
            </label>
          </div>
        </Col>
        {/* <Col>
          <div className='form-check mt-3'>
            <input
              id={`attendee-${index}-term_2`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.term_2}
              onChange={(e) => {
                fillAttendee('term_2', !attendee.term_2, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p>
                <a
                  target='_blank'
                  href={t('attendee.fields.term_2_link')}
                  rel='noreferrer'
                >
                  {t('attendee.fields.term_2')}
                </a>
                .
              </p>
            </label>
          </div>
        </Col> */}
      </Row>
    </>
  );
};
