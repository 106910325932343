import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTickets } from '../../services/product';
import { EVENTID } from '../../helpers/constants';
import { LoadingImg } from '../../helpers/images';
import CustomCard from '../../assets/images/card.webp';

import { TransactionContext } from '../../contexts/TransactionContext';
import { use } from 'i18next';
import Banner from '../../assets/images/banner.webp';
import Banner1920 from '../../assets/images/banner1920.webp';

export default function Login() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState('');
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setEmail, loadedTickets, email, setLoadedTickets } =
    useContext(TransactionContext);

  return (
    <div className='flex flex-col justify-center items-center'>
      <div class='flex justify-center items-center'>
        <div class='flex items-center'>
          <img
            src={Banner1920}
            class='w-full h-[187px] md:w-[970px] md:h-[484px] object-fill'
            alt='Congresso Wainer'
          />
        </div>
      </div>

      <div className='flex flex-col max-w-5xl w-full items-center justify-center lg:p-6 px-3'>
        <div className='grid md:grid-cols-2 items-start max-w-5xl w-full'>
          <div class=' max-lg:text-center sm:p-12 p-4 w-full'>
            <h2 class='text-white lg:text-5xl text-3xl font-bold lg:!leading-[56px]'>
              Onde a Ciência encontra a prática
            </h2>
            <p class='text-white mt-6 text-base leading-relaxed'>
              Com mais de 15 anos de experiência no setor de eventos voltados
              para a psicologia, o Congresso Wainer se destaca como um dos
              principais encontros da área. Desde nossas primeiras Jornadas
              Acadêmicas em 2007, temos trazido grandes nomes internacionais,
              promovendo a troca de conhecimento e a atualização profissional.
            </p>
            <p class='text-white mt-6 text-base leading-relaxed'>
              Junte-se a nós e faça parte dessa jornada de aprendizado e
              crescimento. O Congresso Wainer é mais do que um evento, é uma
              oportunidade de se conectar com especialistas que estão mudando o
              cenário, expandir seus conhecimentos e explorar novas abordagens
              na psicologia.
            </p>
            <p className='text-white mt-6 text-base leading-relaxed'>
              Participe agora e não perca essa chance de se destacar na sua
              carreira!
            </p>
          </div>

          <div class='flex flex-col justify-center items-center gap-6 self-center md:hidden'>
            <div class='bg-gray-900 p-6 rounded-md transition-all relative w-[300px]'>
              <h3 class='text-lg font-bold mb-2 text-white'>
                Minicursos com Palestrantes Internacionais
              </h3>
              <p class='text-sm text-gray-100'>
                Aprenda diretamente com os maiores especialistas da área em
                sessões que oferecem um mergulho profundo em temas relevantes.
              </p>
            </div>
            <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
              <h3 class='text-lg font-bold mb-2 text-white'>
                Espaços de Networking
              </h3>
              <p class='text-sm text-gray-100'>
                Conecte-se com profissionais da psicologia, trocando
                experiências e expandindo sua rede de contatos.
              </p>
            </div>
            <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
              <h3 class='text-lg font-bold mb-2 text-white'>
                Sessões de Autógrafos
              </h3>
              <p class='text-sm text-gray-100'>
                Participe do lançamento de livros e trabalhos científicos,
                interagindo com os autores e pesquisadores.
              </p>
            </div>
            <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
              <h3 class='text-lg font-bold mb-2 text-white'>
                Palestras Interativas
              </h3>
              <p class='text-sm text-gray-100'>
                Aprofunde-se em discussões sobre psicologia na prática, com
                Q&amp;As que incentivam a participação do público.
              </p>
            </div>
            <div class='bg-gray-900 p-6 rounded-md mb-6 w-[300px]'>
              <h3 class='text-lg font-bold mb-2 text-white'>
                Sala VIP de Descanso
              </h3>
              <p class='text-sm text-gray-100'>
                Aproveite um espaço exclusivo para relaxar e se revitalizar
                durante o evento.
              </p>
            </div>
          </div>

          <form
            className='bg-white rounded-xl px-6 py-8 space-y-3 max-w-md md:ml-8 w-full mb-3'
            onSubmit={async (e) => {
              e.preventDefault();
              setLoad(true);
              const response = await getTickets(EVENTID, currentEmail);
              console.log('response', response);
              setLoad(false);
              if (response === null) {
                setErrorMessage(t('tickets.errors.emailnotauthorized'));
                setError(true);
              } else if (response?.length === 0) {
                setErrorMessage(t('tickets.errors.alreadybought'));
                setError(true);
              } else {
                setEmail(currentEmail);
                setLoadedTickets(response);
                navigate('/carrinho');
              }
            }}
          >
            {/* <h3 className='text-2xl font-extrabold mb-12'></h3> */}
            <p className='font-bold'>
              Olá Congressista 2024, preencha o seu e-mail abaixo e descubra as
              condições exclusiva que preparamos para você:
            </p>
            <div>
              <input
                name='email'
                type='email'
                autoComplete='off'
                required
                className='bg-gray-100 focus:bg-transparent w-full text-sm px-4 py-3.5 rounded-md outline-gray-300'
                placeholder='Email'
                onChange={(e) => {
                  const { value } = e.target;
                  setCurrentEmail(value);
                }}
              />
              <span
                className='text-xs text-red-500'
                hidden={!error}
              >
                {errorMessage}
              </span>
              <div className='mt-3'>
                <span className='text-sm'>
                  <a
                    href='https://api.whatsapp.com/send?phone=555195018438&text=Ol%C3%A1,%20eu%20sou%20Congressista%202024%20e%20estou%20com%20problemas%20para%20comprar%20meu%20ingresso%20do%20Congresso%20Wainer%202025%20pelo%20site'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Tem algum problema? Fale com um consultor para garantir o
                    seu ingresso exclusivo.
                  </a>
                </span>
              </div>
            </div>

            <div>
              <button
                type='submit'
                //className='w-full shadow-xl py-3 px-6 text-sm font-semibold rounded-md text-dark bg-yellow-400 hover:bg-blue-400 focus:outline-none'
                className='w-full shadow-xl px-4 py-2 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
                disabled={load}
              >
                {load ? <>Processando...</> : <>Acessar</>}
              </button>
            </div>
          </form>
        </div>

        <div class='md:grid hidden lg:grid-cols-2 md:grid-cols-2 gap-6 px-4 md:self-start'>
          <div class='bg-gray-900 p-6 rounded-md transition-all relative w-[300px]'>
            <h3 class='text-lg font-bold mb-2 text-white'>
              Minicursos com Palestrantes Internacionais
            </h3>
            <p class='text-sm text-gray-100'>
              Aprenda diretamente com os maiores especialistas da área em
              sessões que oferecem um mergulho profundo em temas relevantes.
            </p>
          </div>
          <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
            <h3 class='text-lg font-bold mb-2 text-white'>
              Espaços de Networking
            </h3>
            <p class='text-sm text-gray-100'>
              Conecte-se com profissionais da psicologia, trocando experiências
              e expandindo sua rede de contatos.
            </p>
          </div>
          <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
            <h3 class='text-lg font-bold mb-2 text-white'>
              Sessões de Autógrafos
            </h3>
            <p class='text-sm text-gray-100'>
              Participe do lançamento de livros e trabalhos científicos,
              interagindo com os autores e pesquisadores.
            </p>
          </div>
          <div class='bg-gray-900 p-6 rounded-md w-[300px]'>
            <h3 class='text-lg font-bold mb-2 text-white'>
              Palestras Interativas
            </h3>
            <p class='text-sm text-gray-100'>
              Aprofunde-se em discussões sobre psicologia na prática, com
              Q&amp;As que incentivam a participação do público.
            </p>
          </div>
          <div class='bg-gray-900 p-6 rounded-md mb-6 w-[300px]'>
            <h3 class='text-lg font-bold mb-2 text-white'>
              Sala VIP de Descanso
            </h3>
            <p class='text-sm text-gray-100'>
              Aproveite um espaço exclusivo para relaxar e se revitalizar
              durante o evento.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
