import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { getTickets2 } from '../../services/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
import { Tickets } from '../../components/tickets';
import Event from '../../components/event';
import { EVENTID } from '../../helpers/constants';
import Banner from '../../assets/images/banner.webp';
import Banner1920 from '../../assets/images/banner1920.webp';
import { useError } from '../../hooks/useError';

export const Cart = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { setCart, firstStep, email, loadedTickets } =
    useContext(TransactionContext);
  const [tickets, setTickets] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { goToFieldWithError } = useError();
  const updateTicketCount = (value, index) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      const currentCount = newState[index].count;
      const newCount = currentCount + value;
      if (newState[index] === undefined || newCount > 1 || newCount < 0) {
        return newState;
      }
      newState[index] = { ...newState[index], count: newCount };
      return newState;
    });

  const handleAddTicket = (e, index) => {
    const ticketOnCart = tickets.filter((ticket) => ticket.count > 0);

    if (ticketOnCart.length === 0) {
      updateTicketCount(1, index);
      setDisabled(true);
      goToFieldWithError('tickets-buy');
    }
  };

  const handleRemoveTicket = (e, index) => {
    const ticketOnCart = tickets[index].count === 1;
    if (ticketOnCart) {
      updateTicketCount(-1, index);
      setDisabled(false);
    }
  };

  const handleBuy = (e) => {
    e.preventDefault();
    const ticketToBuy = tickets.filter((ticket) => ticket.count > 0);
    if (ticketToBuy.length > 0) {
      setCart(ticketToBuy);
      navigate('/pagamento');
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      i18n.changeLanguage('pt');
      document.documentElement.setAttribute('lang', t('language'));
      document.title = t('title');
      const response =
        email === '' ? await getTickets2(EVENTID) : loadedTickets;
      const newTickets = response.map((ticket) => ({
        ...ticket,
        count: 0,
      }));
      setTickets(newTickets);
      firstStep();
    };
    fetchTickets();
  }, []);

  return (
    <div className=' max-h-screen'>
      {/* <Header /> */}
      <div class='flex justify-center items-center'>
        <div class='flex flex-col items-center'>
          <img
            src={Banner1920}
            class='w-full h-[187px] md:w-[970px] md:h-[484px] object-fill'
            alt='Congresso Wainer'
          />
          <div class='flex flex-col  h-[187px] md:w-[970px] md:h-[484px] mt-6 ms-3 me-3'>
            {email === '' && (
              <>
                <h2 class='text-white lg:text-5xl text-3xl font-bold lg:!leading-[56px]'>
                  Onde a Ciência encontra a prática
                </h2>

                <p class='text-white mt-6 text-base leading-relaxed'>
                  Com mais de 15 anos de experiência no setor de eventos
                  voltados para a psicologia, o Congresso Wainer se destaca como
                  um dos principais encontros da área. Desde nossas primeiras
                  Jornadas Acadêmicas em 2007, temos trazido grandes nomes
                  internacionais, promovendo a troca de conhecimento e a
                  atualização profissional.
                </p>

                <p class='text-white mt-6 text-base leading-relaxed'>
                  Junte-se a nós e faça parte dessa jornada de aprendizado e
                  crescimento. O Congresso Wainer é mais do que um evento, é uma
                  oportunidade de se conectar com especialistas que estão
                  mudando o cenário, expandir seus conhecimentos e explorar
                  novas abordagens na psicologia.
                </p>
                <p className='text-white mt-6 text-base leading-relaxed'>
                  Participe agora e não perca essa chance de se destacar na sua
                  carreira!
                </p>
              </>
            )}

            <p className='text-white mt-6 text-base leading-relaxed'>
              Ingresso individual e intransferível. Apenas 1 ingresso por
              pessoa.
            </p>
            <Tickets
              handleAdd={handleAddTicket}
              handleRemove={handleRemoveTicket}
              handleBuy={handleBuy}
              tickets={tickets}
              t={t}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'></div>
    </div>
  );
};
